import styled, { css } from 'styled-components';
import { Theme } from 'src/components/Theme';
import { Variant } from 'src/components/Button/ButtonOptions';

export const buttonCss = css`
  font-size: ${Theme.fontSize.xsmall};
  font-weight: ${Theme.fontWeight.semiBold};
  font-family: ${Theme.fontFamily.secondaryFont};
  cursor: pointer;
  border-radius: 2rem;
  color: ${props => props.color};
  background: ${props => (props.variant === Variant.filled ? props.$bgColor : 'none')};
  border: 0.063rem solid  ${props => (props.variant === Variant.filled ? 'transparent' : props.color)};;
  box-sizing: border-box;
  border-radius: 2rem;
  padding: 0.5rem 2.5rem;
  &:hover {
    background:  ${props => (props.variant === Variant.filled ? props.hover : props.$bgColor)};
    border-color: ${props => (props.variant === Variant.filled ? props.color : 'transparent')};
    transition: ${Theme.transition.ease};
  }
  &:disabled {
    opacity: 0.4;
  }

`;

export const StyledButton = styled.button`
  ${buttonCss}
`;
