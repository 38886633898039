import { Button } from 'src/components/Button/Button';
import { Size } from 'src/components/Button/ButtonOptions';
import { LinkButton } from 'src/components/Button/LinkButton';
import { H7 } from 'src/components/Headers/Headers.style';
import { ExclamationIcon } from 'src/assets/icons/ExclamationIcon';
import { SuccessIcon } from 'src/assets/icons/SuccessIcon';
import { Spinner } from 'src/components/Loaders/Spinner';
import { Container, Error, ModalPaper } from 'src/components/Modal/Modal.styles';
import { ProgressSpinner } from 'src/components/Loaders/ProgressSpinner';

export const Modal = ({
  isOpen,
  type,
  bigTitle,
  title,
  error,
  text,
  description,
  buttonText,
  buttonDisabled,
  linkText,
  linkText2,
  buttonAction,
  linkAction,
  linkAction2,
  children,
  progress,
}) => {
  return (
    <Container $isOpen={isOpen} data-testid="modal">
      <ModalPaper>
        {type === 'success' && <SuccessIcon />}
        {type === 'error' && <ExclamationIcon />}
        {type === 'loading' && <Spinner />}
        {type === 'progress-spinner' && <ProgressSpinner progress={progress}/>}
        {bigTitle && <div className='H3'>{bigTitle}</div>}
        {title && <div className='H5'>{title}</div>}
        {error && (
          <Error>
            <ExclamationIcon /> {error}
          </Error>
        )}
        {text && <H7>{text}</H7>}
        {description && <p>{description}</p>}
        {children}
        {buttonText && (
          <Button size={Size.medium} disabled={buttonDisabled} onClick={buttonAction}>
            {buttonText}
          </Button>
        )}
        {linkText && <LinkButton onClick={linkAction}>{linkText}</LinkButton>}
        {linkText2 && <LinkButton onClick={linkAction2}>{linkText2}</LinkButton>}
      </ModalPaper>
    </Container>
  );
};
