import styled, { css } from 'styled-components';
import { Paper } from 'src/components/Paper/Paper';
import { Theme } from 'src/components/Theme';

export const CircleWrapper = styled.span`
  margin: 0rem 0.25rem;
`;

export const Wrapper = styled.div`
  p {
    text-align: center;
  }
`;

export const LinkWrapper = styled.div`
  text-align: center;
`;

export const Flex = styled.div`
  display: flex;
  flex-flow: column wrap;
  ${Theme.breakpoints.laptop} {
    justify-content: center;
    align-content: center;
    flex-flow: row;
  }
`;

const PaperStyle = css`
  margin: 2rem;
  padding: 0.938rem;
  h3,
  p {
    font-weight: 600;
    text-align: left;
    padding: 0rem 0.938rem;
  }
  p ,label ,span {
    font-size: ${Theme.fontSize.xsmall};
  }
  ${Theme.breakpoints.laptop} {
    margin: 2rem 1.25rem;
  }
`;

export const WrapperPaper = styled(Paper)`
  ${PaperStyle}
  width: 20rem;
  height: 40rem;
`;

export const MainWrapper = styled.div`
  height: 20%;
  margin-top: 2rem;
  margin-bottom: -0.5rem;
`;

export const Row = css`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: -1.5rem;
`;

export const DotContainer = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 flex-flow: row;
`;

export const RowNumber = styled.div`
  ${Row}
  margin-top: 2rem;
`;

export const Column = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: 1rem;
  span {
    color:${Theme.colors.orange};
    font-weight: 600;
    &:hover{
      font-weight: 900;
      color:${Theme.colors.red};
    }
  }
`;

export const InputExtra = ({ error, children, placeholder, isChecked, ...props }) => (
  <TextContainer>
    <StyledText $isChecked={isChecked} {...props} placeholder={placeholder} />
    {children}
    {error && <InputError>{error}</InputError>}
  </TextContainer>
);

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 2.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`;

const InputError = styled.span`
  color: red;
`;

export const Label = css`
  color: ${Theme.colors.darkgray};
  font-weight: normal;
  opacity: 0.75;
  pointer-events: none;
  text-shadow: none;
`;

const Styled = css`
  display: flex;
  text-shadow: none;
  border: none;
  box-sizing: border-box;
  flex: 1 1 auto;
`;

const StyledText = styled.input`
  ${Styled}
  padding: 0.625rem 1.25rem;
  border-radius: 0.375rem;
  border: 0.063rem solid ${Theme.colors.darkgray};
  pointer-events: ${props => (props.$isChecked ? 'none' : 'auto')};
  opacity: ${props => (props.$isChecked ? '0.1' : '1')} ;
  &:focus {
    font-weight: 600;
    outline: 0;
    color: ${Theme.colors.black};
    border: 0.063rem solid ${Theme.colors.black};
  }
  ::placeholder {
    ${Label}
  }
`;