import { useEffect, useState } from 'react';
import { ButtonWrapper, StyledHalfButton } from 'src/components/Button/ToggleButton.styles';

export const ToggleButton = ({ labels, value, disabled = false, onChange, ...props }) => {
  const [selected, setSelected] = useState(labels?.[0]);

  // Set default state with useEffect since the default state depends on props (otherwise it will not update).
  useEffect(() => {
    setSelected(value || labels?.[0]);
  }, [value, labels]);

  // When the user clicks one of the buttons, set which is selected and call the callback.
  const onClick = (label) => {
    setSelected(label);
    onChange(label);
  };

  const HalfButton = ({ label, ...props }) => (
    <StyledHalfButton
      disabled={disabled}
      onClick={() => onClick(label)}
      $active={label === selected}
      {...props}
    >
      {label}
    </StyledHalfButton>
  );

  return (
    <ButtonWrapper>
      <HalfButton data-testid={`${props?.$dataTestid}-Left`} $left={true} label={labels?.[0]} {...props} />
      <HalfButton data-testid={`${props?.$dataTestid}-Right`} label={labels?.[1]} {...props} />
    </ButtonWrapper>
  );
};
