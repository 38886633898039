import styled from 'styled-components';
import { Theme } from 'src/components/Theme';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0rem;
`;

export const StyledHalfButton = styled.button`
  cursor: pointer;
  padding: 0.625rem 2rem;
  color: ${props => (props.$active ? Theme.colors.black : Theme.colors.darkgray)};
  background: ${props => (props.$active ? Theme.colors.gray : Theme.colors.lightgray)};
  border: 0.063rem solid ${props => (props.$active ? Theme.colors.black : Theme.colors.darkgray)};
  box-sizing: border-box;
  border-radius: ${props =>
    props.$left ? '0.375rem 0rem 0rem 0.375rem' : '0rem 0.375rem 0.375rem 0rem'};
  &:disabled {
    opacity: 0.4;
  }
`;
